import services from '../services'
import { reactive } from 'vue'
import { useStore } from 'vuex'
import useNotificationToast from './useNotificationToast'

export default function () {
    
    const stateClients = reactive({
        clients: [],
        loading: {
            clients: false,
        },
    })

    const store = useStore()
    const { error } = useNotificationToast()
    
    function loadClientsWithoutPagination() {

        stateClients.loading.clients = true
        const token = store.getters.getToken

        services.clients.getAll({ params: {
            page: null,
            perPage: null,
        }, token })
            .then((response) => {
                stateClients.clients = response.data.clients
            })
            .catch(() => {
                error("Não foi possível carregar os clientes.")
                stateClients.loading.clients = false
            })
            .finally(() => {  stateClients.loading.clients = false })
            
    }

    return {
        stateClients,
        loadClientsWithoutPagination,
    }
}