import {reactive, watch} from 'vue'
import moment from 'moment'

export default function (loadDataFunction) {
    
    const stateCalendarFilter = reactive({
        datesInterval: '',
        calendarFilterOptions: [
            {
                label: 'Esta Semana',
                icon: 'pi pi-calendar',
                command: () => {
                    filterThisWeek()
                }
            },
            {
                label: 'Este Mês',
                icon: 'pi pi-calendar',
                command: () => {
                    filterThisMonth()
                }
            },
            {
                label: 'Este Ano',
                icon: 'pi pi-calendar',
                command: () => {
                    filterThisYear()
                }
            },
            {
                label: 'Tudo',
                icon: 'pi pi-calendar',
                command: () => {

                    stateCalendarFilter.date.begin = ''
                    stateCalendarFilter.date.end = ''

                    loadDataFunction()
                }
            },
        ],
        date: {
            begin: '',
            end: '',
        },
         periodLabelFilter: 'Todos',
    })

    watch(() => stateCalendarFilter.datesInterval, (newValue) => {

        if (newValue == null) {
            stateCalendarFilter.date.begin = ''
            stateCalendarFilter.date.end = ''
        } else {

            stateCalendarFilter.date.begin = moment(newValue[0]).format('YYYY/MM/DD')
            stateCalendarFilter.date.end = newValue[1] ? moment(newValue[1]).format('YYYY/MM/DD') : ''
        }

    })

    function getDateByStartsOf(period) {
        return moment().startOf(period).format('YYYY/MM/DD')
    }

    function getDateOfToday() {
        return moment().format('YYYY/MM/DD')
    }

    function filterToday() {

        stateCalendarFilter.datesInterval = null

        stateCalendarFilter.date.begin = getDateOfToday()

        loadDataFunction('Hoje')

    }

    function filterThisWeek() {

        stateCalendarFilter.datesInterval = null

        stateCalendarFilter.date.begin = getDateByStartsOf('week')
        stateCalendarFilter.date.end = getDateOfToday()

        loadDataFunction('Esta Semana')

    }

    function filterThisMonth() {

        stateCalendarFilter.datesInterval = null

        stateCalendarFilter.date.begin = getDateByStartsOf('month')
        stateCalendarFilter.date.end = getDateOfToday()

        loadDataFunction('Este Mẽs')

    }

    function filterThisYear() {

        stateCalendarFilter.datesInterval = null

        stateCalendarFilter.date.begin = getDateByStartsOf('year')
        stateCalendarFilter.date.end = getDateOfToday()

        loadDataFunction('Este Ano')

    }

    
    return {
        stateCalendarFilter,
        filterToday
    }
}