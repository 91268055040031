export default function () {

    const typesLabels = {
        DEPOSIT: 'DEPÓSITO',
        WITHDRAW: 'SAQUE',
        REMOVAL: 'RETIRADA',
        MANUAL: 'MANUAL',
    }

    const statusLabels = {
        PENDING: 'PENDENTE',
        PAID: 'PAGO',
        CANCELED: 'CANCELADO',
        REFUSED: 'RECUSADO',
        EXPIRED: 'EXPIRADO',
        REFUNDED: 'REEMBOLSADO',
        PENDING_REFUNDED: 'REEMBOLSO PENDENTE',
    }

    const typesOptions = [
        {name: 'DEPÓSITO', value: 'DEPOSIT'},
        {name: 'SAQUE', value: 'WITHDRAW'},
        {name: 'RETIRADA', value: 'REMOVAL'},
        {name: 'MANUAL', value: 'MANUAL'},
    ]

    const statusOptions = [
        {name: 'PENDENTE', value: 'PENDING'},
        {name: 'PAGO', value: 'PAID'},
        {name: 'CANCELADO', value: 'CANCELED'},
        {name: 'EXPIRED', value: 'REFUSED'},
        {name: 'PENDENTE', value: 'EXPIRADO'},
        {name: 'REEMBOLSADO', value: 'REFUNDED'},
        {name: 'REEMBOLSO PENDENTE', value: 'PENDING_REFUNDED'},
    ]

    const pixKeyTypesLabels = {
        CPF: 'CPF',
        CNPJ: 'CNPJ',
        PHONE: 'CELULAR',
        EMAIL: 'EMAIL',
        RANDOM_KEY: 'CHAVE ALEATÓRIA',
    }

    return {
        typesLabels,
        statusLabels,
        typesOptions,
        statusOptions,
        pixKeyTypesLabels
    }
    
}